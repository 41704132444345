<template>
  <div>
    {{ getPlatformName() }}
  </div>
</template>

<script>

export default {
  name: "PlatformCell",
  props: ["platformId"],
  methods: {
    getPlatformName() {
      let item = this.$store.getters.getPlatformName(this.platformId)
      if (item) {
        return item
      }
      return this.platformId
    }
  }
}
</script>

<style scoped>

</style>
