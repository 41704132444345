<template>
  <v-date-picker
    class="date-range-picker"
    is-range
    v-model="dateRange"
  >
    <template v-slot="{ inputValue, inputEvents, isDragging }">
      <div class="d-inline-flex">
        <CInputGroup>
          <CInputGroupText><CIcon name="cil-calendar" /></CInputGroupText>
          <CFormInput
            :value="inputValue.start"
            v-on="inputEvents.start"
            placeholder="Выберите..."
          />
          <CInputGroupText><CIcon name="cil-arrow-right" /></CInputGroupText>
          <CFormInput
            :value="inputValue.end"
            v-on="inputEvents.end"
            placeholder="Выберите..."
          />
        </CInputGroup>
      </div>
    </template>
  </v-date-picker>

  <!--    class="datepicker"-->
  <!--    :closeOnAutoApply = "true"-->
  <!--    @update:modelValue="selectDate"-->
  <!--    locale="ru-RU"-->
  <!--    format="yyyy-MM-dd 00:00:00"-->
  <!--    :autoApply="true"-->
</template>

<script>
export default {
  name: "DateRangePicker",
  components: {},
  emits: ["input"],
  props: {
    dateRange: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      // dateRange: {}
    }
  },
  methods: {
    selectDateRange(dateRange) {
      this.$emit('input',
        {
          start: this.getFormattedDate(dateRange.start),
          end: this.getFormattedDate(dateRange.end)
        }
      )
    },
    getFormattedDate(date) {
      if (Object.prototype.toString.call(date) !== '[object Date]')
      {
        date = new Date(date)
      }
      console.log(date)
      return date.getFullYear()+'-'+
        this.fillDateValue(date.getMonth()+1)+'-'+
        this.fillDateValue(date.getDate())+' 00:00:00'
    },
    fillDateValue(value) {
      if (value < 10) {
        return "0" + value
      } else {
        return value
      }
    }
  },
  watch: {
    dateRange: function(newValue) {
      this.selectDateRange(newValue)
    }
  }
}
</script>

<style scoped>
.date-range-picker {
}
</style>
