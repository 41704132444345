<template>
  <BaseSelect
    :options="getItems()"
    placeholder="Выберите..."
  />
</template>

<script>
import BaseSelect from "../BaseSelect";
export default {
  name: "SelectFilter",
  components: {BaseSelect},
  props: {
    value: null,
    data: { type: Array, default: []}
  },
  methods: {
    getItems() {
      let items = this.data.map(item=> {
        let id = ""

        if ("ID" in item) {
          id = item.ID
        }
        if ("id" in item) {
          id = item.id
        }

        return {
          value: id,
          text: item.value,
          selected: this.value == id
        }
      })
      return items
    }
  }
}
</script>

<style scoped>

</style>
