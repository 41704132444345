<template>
  <span class="pagination pages-info">страниц {{ Math.min(visiblePages, getPagesCount()) }} из {{ getPagesCount() }}</span>
  <div class="d-inline-flex">

    <CSmartPagination
      class="pagination"
      :active-page="currentPageInfo.page"
      :pages="getPagesCount()"
      :size="currentPageInfo.size"
      @activePageChange="pageChange"
      :dots="false"
      :limit="visiblePages"
    />
    <CDropdown  color="secondary" class="d-block">
      <CDropdownToggle variant="ghost" color="secondary">
        на странице {{ currentPageInfo.size }}
      </CDropdownToggle>

      <CDropdownMenu>
        <CDropdownItem component="button" @click="sizeChange(variant)" v-for="variant in pagesVariants">{{ variant }}</CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  </div>
</template>

<script>
import {watchEffect, ref} from "vue";

export default {
  name: "TablePagination",
  emits:["pageChange"],
  props: {
    pageInfo:{
      type: Object,
      default: {
        page:0
      }
    }
  },
  setup(props) {
    let currentPageInfo = ref({})
    let pagesVariants = ref([25, 50, 75, 100])
    let visiblePages = ref(5)
    watchEffect(()=>currentPageInfo.value = props.pageInfo)
    return {
      currentPageInfo,
      pagesVariants,
      visiblePages
    }
  },

  methods: {
    sizeChange(size) {
      console.log(size)
      this.$emit("pageChange", { page: 1, size: size })
    },
    pageChange(page) {
      console.log(page)
      this.$emit("pageChange", { page: page, size: this.currentPageInfo.size })
    },
    getPagesCount() {
      let count = Math.ceil((this.currentPageInfo.total+0) / (this.currentPageInfo.size+0))
      console.log(count)
      return count
    }
  }
}
</script>

<style scoped>
.pagination {
  margin: 0 1rem;
}
.pages-info {
  font-size: 5pt;
  color:var(--cui-secondary);
}
</style>
