<template>
  <div class="filter-panel">
    <component
      v-for="(filter, index) in filters"
      :key="index"
      :is="getComponent(filter.type)"
      v-bind="getProps(filter)"
      v-on="getEvents(filter)"
    />
  </div>
</template>

<script>
import SelectFilter from "./filter/SelectFilter";
import DateRangePicker from "./form/DateRangePicker";
import TextFilter from "./filter/TextFilter";
import {ref} from "vue";


export default {
  name: "FilterWidget",
  components: {SelectFilter, TextFilter, DateRangePicker},
  emits: ['search'],
  props: {
    filters: {
      type: Array,
      default: null
    }
  },
  setup(props) {
    let form = ref({})
    props.filters && props.filters.forEach(filter => {
      form.value[filter.name] = filter.value
    })
    return {
      form
    }
  },
  methods: {
    getComponent(type) {
      switch (type) {
        case "text":
          return "TextFilter"
        case "select":
          return "SelectFilter"
        case "button":
          return "CButton"
        case "daterange":
          return "DateRangePicker"
        default:
          return "div"
      }
    },
    getProps(filter) {
      switch (filter.type) {
        case "text":
          return {
            placeholder: filter.label,
            value: this.form[filter.name]
          }
        case "select":
          return {
            value: filter.value,
            data: filter.data.map(item => { return {value: item.value, ID: item.id} })
          }
        case "button":
          return {
            color: "primary",
            textContent: filter.label
          }
          case "daterange":
            return {
              // minDate: filter.data.start,
              // maxDate: filter.data.end,
              dateRange: filter.default,
              masks: {input: filter.format}
            }
        default:
          return {}
      }
    },
    getEvents: function (filter) {
      switch (filter.type) {
        case "text":
          return {
            input: event => {
              this.form[filter.name] = event.target.value
            }
          }
        case "select":
        case "daterange":
          return {
            input: item => {
              this.form[filter.name] = item
            }
          }
        case "button":
          return {
            click: e => this.search()
          }
        default:
          return {}
      }
    },
    search() {
      console.log("emit search")
      this.$emit('search', this.form)
    }
  }
}
</script>

<style scoped>
.filter-panel {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0.5rem 0 0.5rem;
}

.filter-panel ::v-deep(.form-multi-select),::v-deep(.date-range-picker),::v-deep(.search-input) {
  width: auto;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.filter-panel ::v-deep(.btn) {
  margin-bottom: 0.5rem;
  margin-left: auto;
}
</style>
