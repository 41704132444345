<template>

  <div>
    <div v-if="items  && items.length > 0 && !isLoading">
      <CTable bordered small striped hover>
        <CTableHead color="dark">
          <CTableRow v-if="columns.row1.length > 0">
            <CTableHeaderCell scope="col" rowspan="0" v-if="addCheckboxes"/>

            <CTableHeaderCell scope="col" rowspan="0"></CTableHeaderCell>
            <CTableHeaderCell
              v-for="(item, index) in columns.row1"
              :rowspan="columns.row2[index] === ''?0:1"
              :colspan="item.count"
            >
              {{ item.label }}
            </CTableHeaderCell>
          </CTableRow>
          <CTableRow>
            <CTableHeaderCell scope="col" rowspan="0" v-if="addCheckboxes"/>

            <RowHeaderCell
              :field="columns.keys[index]"
              :order="fields[columns.keys[index]] && fields[columns.keys[index]].order"
              @orderChange = "item => this.$emit('orderChange', item)"
              v-for="(item, index) in columns.row2.filter(item => item !=='')">{{ item }}</RowHeaderCell>

          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="(row) in items">
            <CTableHeaderCell scope="row" v-if="addCheckboxes">
              <CFormCheck :checked="isRowChecked(row)" @change="selectRow(row)"/>
            </CTableHeaderCell>

            <CTableDataCell v-for="(item) in columns.keys">
              <CListGroup v-if="fields[item] && fields[item].type === '[string]'">
                <CListGroupItem v-for="label in row[item]">{{ label }}</CListGroupItem>
              </CListGroup>
              <div v-else-if="row[item] && row[item].label">
                <CButton color="info" variant="outline" size="sm" @click="action(row[item].data, row)">{{
                    row[item].label
                  }}
                </CButton>
              </div>
              <CurrencyCell v-else-if="item === 'CurrencyID'" :currency-id="row[item]"/>
              <PlatformCell v-else-if="item === 'PlatformID'" :platform-id="row[item]"/>
              <ServicePriceCell v-else-if="item === 'ServicePrice'" :id="row[item]"/>
              <ServiceTypeCell v-else-if="item === 'ServiceTypeID'" :id="row[item]"/>
              <div v-else>
                {{ row[item] }}
              </div>
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
      <TablePagination
        v-if="pageInfo && pageInfo.total && pageInfo.total !== 0"
        :page-info="pageInfo"
        @page-change="item => this.$emit('pageChange', item)"
      />

    </div>
    <CCard v-else text-color="secondary" class="empty-panel align-content-center">
      <CCardBody v-if="isLoading" class="text-center fs-2">
        <CSpinner color="info"/>
      </CCardBody>
      <CCardBody v-else-if="items && items.length === 0" class="text-center fs-2">
        <CIcon size="3xl" name="cil-mood-bad"/>
        <br>
        Странно, ничего не найдено
      </CCardBody>
      <CCardBody v-else class="text-center fs-2">
        <CIcon size="3xl" name="cil-search"/>
        <br>
        Чтобы начать, введите номер абонента
      </CCardBody>
    </CCard>
  </div>
</template>

<script>

import CurrencyCell from "../../widgets/cells/CurrencyCell";
import PlatformCell from "../../widgets/cells/PlatformCell";
import ServicePriceCell from "../../widgets/cells/ServicePriceCell";
import ServiceTypeCell from "../../widgets/cells/ServiceTypeCell";
import TablePagination from "../../widgets/TablePagination";
import RowHeaderCell from "../../widgets/cells/RowHeaderCell";

export default {
  name: "SmcBaseTable",
  components: {RowHeaderCell, TablePagination, ServiceTypeCell, ServicePriceCell, PlatformCell, CurrencyCell},
  emits: ['selectedRows', 'showEmpty', 'showWelcome', 'showData', 'tableAction', 'pageChange', "orderChange"],
  props: {
    isLoading: false,
    addCheckboxes: {type: Boolean, default: false},
    responseData: {}
  },
  data() {
    return {
      selectedItems: [],
      selectedRows: [],
      columns: {
        row1: [],
        row2: [],
      },
      items: Array,
      fields: [],
      pageInfo: {}
    }
  },
  methods: {
    headerClick(item) {

      console.log(this.columns.keys[item])
    },
    parseJson(data) {
      return JSON.parse(data)
    },
    action(data, row) {
      this.$emit('tableAction', data, row)
    },
    selectRow(row) {
      if (this.selectedItems.indexOf(row.ID) >= 0) {
        this.selectedItems = this.selectedItems.filter(item => item !== row.ID)
        this.selectedRows = this.selectedRows.filter(item => item.ID !== row.ID)
      } else {
        this.selectedItems.push(row.ID)
        this.selectedRows.push(row)
      }
      this.$emit('selectedRows', this.selectedRows)
    },
    isRowChecked(row) {
      return row && this.selectedItems.indexOf(row.ID) >= 0
    }
  },

  watch: {
    responseData: function (val) {
      this.selectedItems =[]
      this.selectedRows =[]

      var inlineActions = []
      if (val.data[0].result.operations) {
        inlineActions = val.data[0].result.operations.filter(item => {
          return item.type === 'inline' || item.type === 'inline_form'
        })
      }

      this.items = val.data[0].result.values
      this.fields = val.data[0].result.fields
      this.pageInfo = val.data[0].meta

      if (this.items === null || this.items === {} || this.items === undefined) {
        this.$emit('showWelcome')
        return
      } else if (this.items.length === 0) {
        this.$emit('showEmpty')
        return
      }
      this.$emit('showData')
      let columnsObj = {
        keys: Object.keys(val.data[0].result.header),
        row1: [],
        row2: Object.values(val.data[0].result.header)
      }
      if (columnsObj.row2.filter(item => {
        return item.indexOf("::") >= 0
      }).length > 0) {
        columnsObj.row1 = columnsObj.row2.map(item => {
          if (item.indexOf("::") >= 0) {
            return item.split("::")[0]
          } else {
            return item
          }
        })
        columnsObj.row2 = columnsObj.row2.map(item => {
          if (item.indexOf("::") >= 0) {
            return item.split("::")[1]
          } else {
            return ""
          }
        })
      }

      columnsObj.row1 = columnsObj.row1.map((item, index, self) => {
        return {
          label: item,
          count: self.filter(selfItem => selfItem === item).length
        }

      })

      var resArr = [];
      columnsObj.row1.forEach(function (item) {
        var i = resArr.findIndex(x => (x.label === item.label));
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      columnsObj.row1 = resArr

      inlineActions.forEach(action => {
        // columnsObj.row1[action.name] = ''
        columnsObj.row2.push(action.name)
        // columnsObj.row2.push('')
        columnsObj.keys.push(action.name)
      })

      this.columns = columnsObj

      this.items = this.items.map(row => {
        inlineActions.forEach(action => {
          var actions = [];
          action.data.forEach(item => {
            actions[item] = row[item]
          })
          row[action.name] = {
            label: action.name,
            data: {
              method: action.method,
              controller: action.controller,
              type: action.type === 'inline' ? 'inline-link': action.type,
              data: actions
            }
          }
        })


        return row
      })
    },
    isLoading: function (val) {
      if (val) {
        this.selectedItems = []
      }
    }
  }
}
</script>

<style scoped>
.empty-panel {
  padding: 100pt 0;
}
</style>
