<template>
  <div>
    {{ getName() }}
  </div>
</template>

<script>

export default {
  name: "ServicePriceCell",
  props: ["id"],
  methods: {
    getName() {
      let item = this.$store.getters.getServicePriceName(this.id)
      if (item) {
        return item
      }
      return this.id
    }
  }
}
</script>

<style scoped>

</style>
