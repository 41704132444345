<template>
  <CFormInput class="search-input"/>
</template>

<script>
export default {
  name: "TextFilter"
}
</script>

<style scoped>

</style>
