<template>
  <CTableHeaderCell @click="onClick">
    <div class="cell-header">
      <div><slot></slot></div>
      <CIcon v-if="iconName && iconName==='cil-arrow-bottom'" :name="iconName" />
      <CIcon v-if="iconName && iconName==='cil-arrow-top'" :name="iconName" />
    </div>
  </CTableHeaderCell>
</template>

<script>
export default {
  name: "RowHeaderCell",
  emits: ['orderChange'],
  props: {
    field: {
      type: String
    },
    order: {
      type: String,
      default: '',
      validator: value => {
        return ['','ASC','DESC'].includes(value)
      }
    }
  },
  data() {
    return {
      sortOrder: this.order
    }
  },
  computed: {
    iconName() {
      console.log(this.sortOrder)
      switch (this.sortOrder) {
        case "":
          return ""
        case "ASC":
          return "cil-arrow-bottom"
        case "DESC":
          return "cil-arrow-top"
      }
    }
  },
  methods: {
    onClick() {
      this.nextSortOrder()
    },
    nextSortOrder() {
      switch (this.sortOrder) {
        case "":
          this.sortOrder = "ASC"
          break
        case "ASC":
          this.sortOrder = "DESC"
          break
        case "DESC":
          this.sortOrder = ""
          break
      }

      this.$emit('orderChange', {field: this.field, direction: this.sortOrder})
    }
  }
}
</script>

<style scoped>
.cell-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  cursor: pointer;
}
</style>
