<template>
  <CIcon v-if="currencyId === '74'" :icon="tenge"/>
  <div v-else>
    {{ getCurrencyName() }}
  </div>
</template>

<script>
import { tenge } from '@/assets/icons/tenge'

export default {
  name: "CurrencyCell",
  props: ["currencyId"],
  setup() {
    return {
      tenge
    }
  },
  methods: {
    getCurrencyName() {
      let item = this.$store.getters.getCurrencyName(this.currencyId)
      if (item) {
        return item
      }
      return this.currencyId
    }
  }
}
</script>

<style scoped>

</style>
